import GroupService from '@/services/GroupService'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'

const UserModule = getModule(UserInfo, store)

const updateGroupMaxFileSize = async (groupId: string) => {
  if (!groupId) {
    UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(25)
    return
  }
  return GroupService.getGroupById(groupId)
    .then(res => {
      if (res.status === 200) {
        UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(
          res.data.max_img_size_per_attachment
        )
      } else {
        UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(25)
      }
    })
    .catch(err => {
      UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(25)
    })
}

export default updateGroupMaxFileSize
