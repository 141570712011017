





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Editor from '@tinymce/tinymce-vue'
import GroupService from '@/services/GroupService'
import { ETinyFileType } from '@/models'
import { checkScript } from '@/validations/validation'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'
//@ts-ignore
import _ from 'lodash'

@Component({ components: { Editor } })
export default class TextEditor extends Vue {
  //prop
  @Prop() startData?: string
  @Prop() tinyFileType!: ETinyFileType
  @Prop() maxFileSizeProps?: any

  //data
  private isCommonTopic = !this.$route.params.groupId
  private fileIds: number[] = []
  private editorData: string = ''

  //config tiny editor
  private getTinyConfig: any = {}
  private getKey: string = ''

  //validate
  @Prop() checkEmpty?: boolean
  @Prop() emptyContent?: boolean
  @Prop() overLengthContent?: boolean
  @Prop() includesScriptTagContent?: boolean
  private isEmpty: boolean = false
  private isOverLength: boolean = false
  private isIncludesScriptTag: boolean = false

  private imageType: string[] = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/jpg'
  ]
  private requiredMess: string = this.$t('common.form.needed_item', {
    name: this.$t('common.form.document') as string
  }) as string
  private overLengthMess: string = this.$t('common.form.max_length', {
    name: this.$t('common.form.document') as string,
    length: 20000
  }) as string
  private includesScriptTagMess: string = this.$t('common.form.no_script_tag', {
    name: this.$t('common.form.document') as string
  }) as string
  private maxFileSize: any = {
    mb: this.isCommonTopic
      ? 25
      : Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes: this.isCommonTopic
      ? 25 * 1048576
      : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
  }

  //modal helper
  private modalMess: string = ''
  private randomId: number = Math.random()

  created() {
    this.init()
    this.handleMaxFileSize()
    document.body.classList.add('tiny-z1')
  }

  destroyed() {
    document.body.classList.remove('tiny-z1')
  }

  async handleMaxFileSize() {
    if (this.maxFileSizeProps) {
      this.maxFileSize = this.maxFileSizeProps
    } else {
      await updateGroupMaxFileSize(this.$route.params.groupId)
      this.maxFileSize = {
        mb: this.isCommonTopic
          ? 25
          : Number(this.$store.state.userInfo.user.current_group_max_file_size),
        bytes: this.isCommonTopic
          ? 25 * 1048576
          : Number(
              this.$store.state.userInfo.user.current_group_max_file_size
            ) * 1048576
      }
    }
  }

  /**
   * init for TinyMCE
   */
  init() {
    this.getKey = process.env.VUE_APP_TINY_MCE_API_KEY as string

    this.getTinyConfig = {
      language: this.$store.state.setting.setting.setting_language.code,
      height: 400,
      setup: (editor: any) => {
        editor.ui.registry.addContextToolbar('textselection', {
          predicate: (node: any) => {
            return !editor.selection.isCollapsed()
          },
          items: 'bold italic | quicklink h1 h2 h3 blockquote',
          position: 'selection',
          scope: 'node'
        })
      },
      selector: 'textarea#full-featured',
      deprecation_warnings: false,
      nonbreaking_force_tab: true,
      toolbar_mode: 'wrap',
      menubar: true,
      entity_encoding: 'raw',
      plugins: [
        'advlist emoticons hr image link lists media nonbreaking preview searchreplace table'
      ],
      toolbar: [
        'fontsizeselect | fontselect | formatselect | undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | emoticons preview | image media link | searchreplace hr nonbreaking | table'
      ],
      contextmenu: false,
      quickbars_insert_toolbar: false,
      default_link_target: '_blank',
      image_dimensions: false,
      file_picker_callback: (callback: any, value: any, meta: any) => {
        var input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')

        document.body.appendChild(input)

        input.onchange = (event: any) => {
          const files: File[] = event.target.files || event.dataTransfer.files
          if (!files.length) return
          if (
            files[0].size < this.maxFileSize.bytes &&
            this.imageType.includes(files[0].type)
          ) {
            let formData = new FormData()
            formData.set('file', files[0], files[0].name)
            formData.set('type', String(this.tinyFileType))
            if (this.$route.params.groupId) {
              formData.set('group_id', this.$route.params.groupId)
            }
            this.$blockui.show()
            GroupService.uploadFileTiny(formData)
              .then(res => {
                if (res.status === 200) {
                  callback(res.data.image_path, { title: files[0].name })
                  // if (res.data.file_id) {
                  //   this.fileIds.push(res.data.file_id)
                  // }
                }
              })
              .catch(err => {
                if (
                  err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY'
                ) {
                  this.modalMess = this.$t(
                    'common.message.group_over_size'
                  ) as string
                  this.$bvModal.show(`modal-error-text-editor-${this.randomId}`)
                }
                //@ts-ignore
                tinymce.activeEditor.undoManager.undo()
              })
              .finally(() => this.$blockui.hide())
          } else {
            this.modalMess = this.$t('common.form.img_modified_with_max', {
              max: this.maxFileSize.mb
            }) as string
            this.$bvModal.show(`modal-info-text-editor-${this.randomId}`)
          }
          document.body.removeChild(input)
        }
        input.click()
      },
      images_upload_handler: (blobInfo: any, success: any, failure: any) => {
        if (
          blobInfo.blob().size < this.maxFileSize.bytes &&
          this.imageType.includes(blobInfo.blob().type)
        ) {
          let formData = new FormData()
          formData.set('file', blobInfo.blob(), blobInfo.blob().name)
          formData.set('type', String(this.tinyFileType))
          if (this.$route.params.groupId) {
            formData.set('group_id', this.$route.params.groupId)
          }
          this.$blockui.show()
          GroupService.uploadFileTiny(formData)
            .then(res => {
              if (res.status === 200) {
                success(res.data.image_path)
                // if (res.data.file_id) {
                //   this.fileIds.push(res.data.file_id)
                // }
              }
            })
            .catch(err => {
              if (
                err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY'
              ) {
                this.modalMess = this.$t(
                  'common.message.group_over_size'
                ) as string
                this.$bvModal.show(`modal-error-text-editor-${this.randomId}`)
                //@ts-ignore
                tinymce.activeEditor.undoManager.undo()
              }
            })
            .finally(() => this.$blockui.hide())
        } else {
          this.modalMess = this.$t('common.form.img_modified_with_max', {
            max: this.maxFileSize.mb
          }) as string
          this.$bvModal.show(`modal-info-text-editor-${this.randomId}`)
          //@ts-ignore
          tinymce.activeEditor.undoManager.undo()
        }
      }
    }
  }

  /**
   * Set default data for input
   */
  @Watch('startData')
  setStartData() {
    this.editorData = this.startData ? this.startData : ''
  }

  /**
   * Watching input
   */
  @Watch('editorData')
  getData() {
    const text = this.removeHTML()
    this.$emit('getData', {
      html: this.editorData,
      text: text,
      fileIds: this.fileIds
    })
    if (this.checkEmpty) {
      this.isEmpty = !this.checkPTagInner() && !text.trim()
    }
    this.isOverLength =
      new Blob([text]).size > 80000 || _.toArray(text).length > 20000
    this.isIncludesScriptTag = checkScript(text)
  }

  /**
   * Trigger error when user passing emptyContent = true
   */
  @Watch('emptyContent')
  triggerEmptyError() {
    this.isEmpty = !!(this.emptyContent && this.checkEmpty)
  }

  /**
   * Trigger error when user passing overLengthContent = true
   */
  @Watch('overLengthContent')
  triggerOverLengthError() {
    if (this.overLengthContent) {
      this.isOverLength = true
    }
  }

  /**
   * Trigger error when user passing overLengthContent = true
   */
  @Watch('includesScriptTagContent')
  triggerIncludesScriptTagError() {
    if (this.includesScriptTagContent) {
      this.isIncludesScriptTag = true
    }
  }

  /**
   * Get inner text only
   */
  removeHTML() {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = this.editorData
    return tmp.textContent || tmp.innerText || ''
  }

  /**
   * Check that DOM inner text have another tag
   */
  checkPTagInner() {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = this.editorData
    const elmnt = tmp.getElementsByTagName('p')
    let innerTextofP = ''
    for (let index = 0; index < elmnt.length; index++) {
      const el = elmnt[index]
      innerTextofP = innerTextofP + el.innerHTML
      if (el.innerHTML.includes('<')) break
    }
    return innerTextofP.includes('<')
  }
}
