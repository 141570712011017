const scrollToError = async (refs: any, validationObserverRef: string) => {
  try {
    await (refs[validationObserverRef] as any).handleSubmit()
    const errorsEl = (refs[validationObserverRef] as any).errors
    for (let key of Object.keys(errorsEl)) {
      if (errorsEl[key].length) {
        (refs[key] as any).focus();
        (refs[key] as any).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
        return
      }
    }
  } catch (e: any) { }
}

export default scrollToError
