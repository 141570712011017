




















































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DatePickerLang from '@/helpers/DatePickerLang'
import scrollToError from '@/helpers/ScrollToError'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import ModalMultiSelect from '@/components/Modal/ModalMultiSelect.vue'
import ModalTopicSelectedFile from '@/components/Modal/ModalTopicSelectedFile.vue'
import {
  Topic,
  ETopicType,
  TopicAttachFile,
  ITinyEditorData,
  ETinyFileType,
  IdName,
  OptionSendMail
} from '@/models'
import GroupTopicService from '@/services/GroupTopicService'
import CommonTopicService from '@/services/CommonTopicService'
//@ts-ignore
import _ from 'lodash'
import { checkScript } from '@/validations/validation'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'

/**
 * f2-404
 * f2-404a
 * グループ掲示板のトピック作成
 * グループ 共通トピック作成
 */
@Component({
  components: { TextEditor, ModalMultiSelect, ModalTopicSelectedFile }
})
export default class TopicCreate extends Vue {
  //check that this page is common topic or group
  private isCommonTopic = !this.$route.params.groupId
  //check that this page is topic create or topic update
  private isTopicCreate = !this.$route.params.topicId
  //lang for datepicker
  private datePickProp: DatePickerLang = new DatePickerLang(this)
  //modal helper
  private modalMess: string = ''
  private modalAction: any = () => {}
  //validate
  private checkEmptyEditor: boolean = true
  private overLengthContent: boolean = false
  private includesScriptTagContent: boolean = false
  private emptyContent: boolean = false
  private disableCheckDate: boolean = false
  private maxFileSize: any = {
    mb: this.isCommonTopic
      ? 25
      : Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes: this.isCommonTopic
      ? 25 * 1048576
      : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
  }
  //data
  private createTopic: Topic = new Topic()
  private currentTopic: Topic = new Topic() //used for check before leave
  private groups: GroupNameVM[] = []
  private selectedGroups: GroupNameVM[] = []
  private content_text: string = ''
  private tinyFileIds: number[] = []
  //files
  private fileChooses: (File | TopicAttachFile)[] = []
  private currentFileChooses: (File | TopicAttachFile)[] = [] //used for check before leave
  private chooseFileName: string = this.$t('common.form.not_selected') as string
  //enum
  private eTopicType: any = ETopicType
  private eTinyFileType: any = ETinyFileType
  private selectedGroupsDecription: string = this.$t(
    'common.form.not_selected'
  ) as string
  //handle before leave
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private optionSendMail: any = OptionSendMail

  async created() {
    await this.checkTopicLock()
    this.checkRoleCommonTopic()
    this.hanldeTopicType()
    this.initGroupTag()
    this.hanldeBindingTopic()
    this.handleMaxFileSize()
  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: this.isCommonTopic
        ? 25
        : Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes: this.isCommonTopic
        ? 25 * 1048576
        : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
          1048576
    }
  }

  checkRouterBeforLeave() {
    if (this.isTopicCreate) {
      return (
        _.isEqual(this.createTopic, new Topic()) && !this.fileChooses.length
      )
    }
    return (
      _.isEqual(this.createTopic, this.currentTopic) &&
      _.isEqual(this.currentFileChooses, this.fileChooses)
    )
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('topic-create-modal-confirm-leave')
    } else {
      this.hanldeConfirmLeave()
      next()
    }
  }

  hanldeConfirmLeave() {
    if (!this.isCommonTopic && !this.isTopicCreate) {
      GroupTopicService.unlockEditTopic(
        this.$route.params.groupId,
        this.$route.params.topicId
      )
    }
    this.confirmLeave()
  }

  /**
   * Watch files choose to handle decriptions
   */
  @Watch('fileChooses')
  handleChooseFilesName() {
    this.checkEmptyEditor = !this.fileChooses.length
    this.emptyContent = this.fileChooses.length
      ? false
      : !this.checkPTagInner() && !this.content_text.trim()
    let name = this.$t('common.form.multi_selected', {
      count: this.fileChooses.length
    }) as string
    if (this.fileChooses.length === 0) {
      name = this.$t('common.form.not_selected') as string
      this.$bvModal.hide('display-files')
    }
    if (this.fileChooses.length === 1) {
      name = this.fileChooses[0].name
    }
    this.chooseFileName = name
  }

  checkTopicLock() {
    if (!this.isTopicCreate && !this.isCommonTopic) {
      return GroupTopicService.checkTopicLockByOther(
        this.$route.params.groupId,
        this.$route.params.topicId
      ).catch(err => {
        if (err.response.status === 403 && err.response.data.is_locked) {
          this.confirmLeaveIgnore = true
          this.modalMess = this.$t('common.message.topic_lock', {
            name: err.response.data.is_locked_by.name
          }) as string
          this.modalAction = () => this.jumpToList()
          this.$bvModal.show('modal-info-topic-create')
        }
      })
    }
  }

  checkTopicEditRole() {
    if (this.isCommonTopic) {
      if (
        this.createTopic.creater_id !== this.$store.state.userInfo.user.info.id
      ) {
        this.confirmLeaveIgnore = true
        this.jumpToList()
      }
    } else {
      if (
        !this.createTopic.topic_detail.is_multiple_edit &&
        !this.$store.state.userInfo.user.group_role.role_edit_topic
      ) {
        this.confirmLeaveIgnore = true
        this.jumpToList()
      }
    }
  }

  checkRoleCommonTopic() {
    if (
      this.isCommonTopic
      //  &&
      // !this.$store.state.userInfo.user.common_topic_role
    ) {
      this.confirmLeaveIgnore = true
      this.jumpToList()
    }
  }

  /**
   * Call API get topic detail when this is topic update page
   */
  hanldeBindingTopic() {
    if (!this.isTopicCreate) {
      if (this.isCommonTopic) {
        CommonTopicService.getCommonTopicById(this.$route.params.topicId)
          .then(res => {
            if (res.status === 200) {
              this.hanldeAfterBindingTopic(res)
            }
          })
          .catch(err => this.handleTopicNotFound(err))
      } else {
        GroupTopicService.getTopicById(
          this.$route.params.groupId,
          this.$route.params.topicId
        )
          .then(res => {
            if (res.status === 200) {
              this.hanldeAfterBindingTopic(res)
            }
          })
          .catch(err => this.handleTopicNotFound(err))
      }
    }
  }

  /**
   * Handle topic not found
   */
  handleTopicNotFound(err: any) {
    if (err.response.status === 404) {
      this.confirmLeaveIgnore = true
      this.modalMess = this.$t('common.message.topic_not_exist') as string
      this.modalAction = () => this.jumpToList()
    } else {
      this.modalMess = this.$t('common.message.uncatch_error') as string
      this.modalAction = () => {}
    }
    this.$bvModal.show('modal-error-topic-create')
  }

  /**
   * Bind data after call get detail topic successful
   */
  hanldeAfterBindingTopic(res: any) {
    this.createTopic = new Topic(res.data)
    this.content_text = this.removeHTML(this.createTopic.topic_detail.content)
    this.checkTopicEditRole()
    this.fileChooses = this.createTopic.topic_detail.attach_file
    this.selectedGroups = this.createTopic.topic_detail.selected_group.map(
      item => new GroupNameVM(item, true)
    )
    const oldSelectedGroupTagIds = this.selectedGroups.map(item => item.id)
    this.groups.forEach(item => {
      item.selected = oldSelectedGroupTagIds.includes(item.id)
    })
    this.currentTopic = _.cloneDeep(this.createTopic)
    this.currentFileChooses = _.cloneDeep(this.fileChooses)
    this.handleGroupDisplayDecription()
  }

  /**
   * Handle topic tpye
   */
  hanldeTopicType() {
    this.createTopic.topic_detail.topic_type = this.isCommonTopic
      ? ETopicType.COMMON_TOPIC
      : ETopicType.GROUP_TOPIC
  }

  /**
   * Get data from TextEditor
   */
  getEditorData(data: ITinyEditorData) {
    this.createTopic.topic_detail.content = data.html
    this.content_text = data.text
    this.tinyFileIds = data.fileIds
  }

  /**
   * Init of group tag
   *
   * Call API to get all group tag of logined user
   */
  initGroupTag() {
    if (this.isCommonTopic) {
      this.confirmLeaveIgnore = true
      CommonTopicService.getAllGroupAdmin()
        .then(res => {
          if (res.status === 200) {
            this.groups = res.data.map((item: IdName) => {
              let getSeletedItem = this.selectedGroups.find(
                selected => selected.id === item.id
              )
              return new GroupNameVM(item, !!getSeletedItem)
            })
          }
        })
        .catch(err => {
          this.modalMess = this.$t('common.message.uncatch_error') as string
          this.modalAction = () => {}
          this.$bvModal.show('modal-error-topic-create')
        })
    }
  }

  openGroupSelectModal() {
    this.$bvModal.show('multi-select')
    this.$router.replace({ query: { multi_select: '1' } }).catch(() => {}) //for modal multi-select watch
  }

  /**
   * Remove file by click 'x' in files display modal
   */
  removeFileFromFilesModal(index: number) {
    this.fileChooses = this.fileChooses.filter(
      (item: any, i: number) => i !== index
    )
  }

  /**
   * Group tag select area decriptions
   */
  handleGroupDisplayDecription() {
    this.selectedGroupsDecription =
      this.selectedGroups.length === 0
        ? (this.$t('common.form.not_selected') as string)
        : this.selectedGroups.length === 1
        ? this.selectedGroups[0].name
        : (this.$t('groups.new_feed.multi_group_tag') as string)
  }

  /**
   * Handle select group tag
   */
  hanldeSelectedGroup(selected: GroupNameVM[]) {
    this.selectedGroups = selected
    this.groups.forEach(item => {
      item.selected = selected.map(i => i.id).includes(item.id)
    })
    this.handleGroupDisplayDecription()
  }

  /**
   * Handle choose multiple file
   */
  onFileChange(event: any) {
    const files: File[] = event.target.files || event.dataTransfer.files
    if (!files.length) return
    if (this.fileChooses.length + files.length > 30) {
      this.modalMess = this.$t('common.message.over_30_file') as string
      this.modalAction = () => {}
      this.$bvModal.show('modal-info-topic-create-non-close')
      return
    }
    let fileterFile: File[] = []
    let haveSomeFileOverSize: boolean = false
    files.forEach(item => {
      if (item.size > this.maxFileSize.bytes) {
        haveSomeFileOverSize = true
      } else {
        fileterFile.push(item)
      }
    })
    if (haveSomeFileOverSize) {
      this.modalMess = this.$t('common.form.some_file_over_size', {
        max: this.maxFileSize.mb
      }) as string
      this.modalAction = () => {}
      this.$bvModal.show('modal-info-topic-create-non-close')
    }
    this.fileChooses = [...this.fileChooses, ...fileterFile]
  }

  /**
   * Open modal to display all choosen files
   */
  openDisplayFiles() {
    if (this.fileChooses.length) this.$bvModal.show('display-files')
  }

  /**
   * Open modal confirm delete
   */
  deleteConfirm() {
    this.modalMess = this.$t('common.message.delete') as string
    this.$bvModal.show('modal-confirm-delete')
  }

  /**
   * Router jump to list
   */
  jumpToList() {
    this.isCommonTopic
      ? this.$router.push({ name: 'common-topic-new-feed' })
      : this.$router.push({ name: 'group-new-feed' })
  }

  /**
   * Router jump to list
   */
  jumpToListRoute() {
    return this.isCommonTopic
      ? ({ name: 'common-topic-new-feed' })
      : ({ name: 'group-new-feed' })
  }

  /**
   * Confirm modal press "Yes" handle
   */
  confirmDelete() {
    this.$blockui.show()

    if (this.isCommonTopic) {
      CommonTopicService.deleteCommonTopic(this.$route.params.topicId)
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.modalAction = () => this.jumpToList()
            this.$bvModal.show('modal-success-topic-create')
          }
        })
        .catch(err => this.handleDeleteErr(err))
        .finally(() => this.$blockui.hide())
    } else {
      GroupTopicService.moveTopicsToBin(this.$route.params.groupId, [
        Number(this.$route.params.topicId)
      ])
        .then(res => {
          if (res.status === 200) {
            this.modalMess = this.$t('common.message.deleted') as string
            this.modalAction = () => this.jumpToList()
            this.$bvModal.show('modal-success-topic-create')
          }
        })
        .catch(err => this.handleDeleteErr(err))
        .finally(() => this.$blockui.hide())
    }
  }

  handleDeleteErr(err: any) {
    if (
      err.response.status === 404 &&
      (err.response.data.message === 'topic_has_been_deleted' ||
        err.response.data.message === 'topic_not_found')
    ) {
      this.modalMess = this.$t('common.message.topic_not_exist') as string
    } else {
      this.modalMess = this.$t('common.message.delete_fail') as string
    }
    this.modalAction = () => this.jumpToList()
    this.$bvModal.show('modal-error-topic-create')
  }

  /**
   * Convert data to formdata
   */
  formDataToSubmit(instantAction: boolean = false) {
    let formData = new FormData()
    const format = 'YYYY/MM/DD HH:mm:ss'
    const content = this.createTopic.topic_detail.content
    const multiEdit = this.createTopic.topic_detail.is_multiple_edit
    const emergencyContact = this.createTopic.topic_detail.emergency_contact
    const likeChar = this.createTopic.topic_detail.like_character_change?.trim()
    const deliveryDate = this.createTopic.topic_detail.delivery_date
    const deliveryDateMoment = this.moment(deliveryDate).format(format)
    formData.set('topic_type', String(this.createTopic.topic_detail.topic_type))
    formData.set('topic_title', this.createTopic.topic_detail.topic_title)
    formData.set('content', content ? content : '')
    formData.set('content_text', this.content_text ? this.content_text : '')
    formData.set('is_multiple_edit', multiEdit ? '1' : '0')
    formData.set('emergency_contact', emergencyContact as any)
    formData.set('like_character_change', likeChar ? likeChar : '')
    formData.set('delivery_date', deliveryDateMoment)
    if (instantAction) {
      formData.set('is_instant', '1')
    }

    //set how_nice
    if (this.createTopic.topic_detail.enable_like || !this.isCommonTopic) {
      formData.append('how_nice[]', '1')
    }
    if (this.createTopic.topic_detail.hide_liker) {
      formData.append('how_nice[]', '2')
    }

    //set comment_options
    if (this.createTopic.topic_detail.enable_comment) {
      formData.append('comment_options[]', '1')
    }
    if (this.createTopic.topic_detail.allow_anonymous_comment) {
      formData.append('comment_options[]', '2')
    }

    //handle attach file
    if (this.isTopicCreate) {
      this.fileChooses.forEach(file => {
        formData.append('attach_file[]', file as File)
      })
    } else {
      this.handleAttachFilesForUpdate(formData)
    }

    //for tiny editor
    this.tinyFileIds.forEach(id => {
      formData.append('file_ids[]', String(id))
    })

    //options for common topic only
    if (this.isCommonTopic) {
      formData.set(
        'hide_to_selected_group_tag',
        this.createTopic.topic_detail.hide_to_selected_group_tag ? '1' : '0'
      )
      this.selectedGroups.forEach(groupTag => {
        formData.append('selected_group[]', String(groupTag.id))
      })
    }
    return formData
  }

  /**
   * Handle convert fileChooses to delete_attach_file_ids[] and add_attach_files[]
   */
  handleAttachFilesForUpdate(formData: FormData) {
    this.fileChooses
      .filter((item: any) => !item.id)
      .map(file => {
        formData.append('add_attach_files[]', file as File)
      })

    const oldAttachFileIds: number[] = this.createTopic.topic_detail.attach_file.map(
      (item: any) => item.id
    )
    const keepFileIds: number[] = this.fileChooses
      .filter((item: any) => item.id)
      .map((item: any) => item.id)

    oldAttachFileIds
      .filter(id => !keepFileIds.includes(id))
      .map(id => {
        formData.append('delete_attach_file_ids[]', String(id))
      })
  }

  /**
   * Submit create topic
   *
   * Call API submit form
   */
  async onSubmitCreateTopic(valid: boolean, instantAction: boolean = false) {
    if (this.handleContentError() && this.validateSelectGroupTag() && valid) {
      this.confirmLeaveIgnore = true
      this.disableCheckDate = true
      let formData = this.formDataToSubmit(instantAction)
      if (this.isCommonTopic) {
        if (this.isTopicCreate) {
          this.createCommonTopic(formData)
        } else {
          this.updateCommonTopic(formData)
        }
      } else {
        if (this.isTopicCreate) {
          this.createGroupTopic(formData)
        } else {
          this.updateGroupTopic(formData)
        }
      }
    } else {
      scrollToError(this.$refs, 'topicCreate')
    }
  }

  /**
   * Submit create topic and delivery now
   *
   * Call API create topic
   */
  submitAndUploadNow(valid: boolean) {
    let instantAction = true
    this.createTopic.topic_detail.delivery_date = new Date()
    ;(this.$refs as any).topicCreate.handleSubmit()
    setTimeout(() => {
      const errors = (this.$refs as any).topicCreate.errors
      delete errors.upload_date
      let valid = true
      for (const [key, value] of Object.entries(errors)) {
        if ((value as any).length) {
          valid = false
          break
        }
      }
      this.onSubmitCreateTopic(valid, instantAction)
    }, 1)
  }

  /**
   * Call API to create new topic in common topic
   */
  createCommonTopic(formData: FormData) {
    this.$blockui.show()
    CommonTopicService.createCommonTopic(formData)
      .then(res => {
        if (res.status === 201) {
          this.modalMess = this.$t('common.message.created') as string
          this.modalAction = () => this.jumpToList()
          this.$bvModal.show('modal-success-topic-create')
        }
      })
      .catch(err => {
        this.submitError(err)
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to update topic in common topic
   */
  updateCommonTopic(formData: FormData) {
    this.$blockui.show()
    CommonTopicService.updateCommonTopic(this.$route.params.topicId, formData)
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.updated') as string
          this.modalAction = () => this.jumpToList()
          this.$bvModal.show('modal-success-topic-create')
        }
      })
      .catch(err => {
        this.submitError(err)
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to create new topic in group topic
   */
  createGroupTopic(formData: FormData) {
    this.$blockui.show()
    GroupTopicService.createNewTopic(this.$route.params.groupId, formData)
      .then(res => {
        if (res.status === 201) {
          this.modalMess = this.$t('common.message.created') as string
          this.modalAction = () => this.jumpToList()
          this.$bvModal.show('modal-success-topic-create')
        }
      })
      .catch(err => {
        this.submitError(err)
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Call API to update new topic in group topic
   */
  updateGroupTopic(formData: FormData) {
    this.$blockui.show()
    GroupTopicService.updateGroupTopic(
      this.$route.params.groupId,
      this.$route.params.topicId,
      formData
    )
      .then(res => {
        if (res.status === 200) {
          this.modalMess = this.$t('common.message.updated') as string
          this.modalAction = () => this.jumpToList()
          this.$bvModal.show('modal-success-topic-create')
        }
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.modalMess = this.$t('common.message.topic_not_exist') as string
          this.modalAction = () => this.jumpToList()
        } else if (
          err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY'
        ) {
          this.modalMess = this.$t('common.message.group_over_size') as string
          this.modalAction = () => {}
        } else if (err.response.status === 403) {
          if (
            err.response.data.message === 'locked_by_other_user' &&
            err.response.data.is_locked_by
          ) {
            this.modalMess = this.$t('common.message.topic_edit_sesson_end', {
              name: err.response.data.is_locked_by.name
            }) as string
            this.modalAction = () => this.jumpToList()
          }
          if (err.response.data === 'topic_has_been_updated') {
            this.modalMess = this.$t(
              'common.message.topic_edited_by_other'
            ) as string
            this.modalAction = () => this.jumpToList()
          }
        } else {
          this.modalMess = this.$t('common.message.update_fail') as string
          this.modalAction = () => this.jumpToList()
        }
        this.$bvModal.show('modal-error-topic-create')
      })
      .finally(() => this.$blockui.hide())
  }

  /**
   * Handle call API create/update topic fail
   */
  submitError(err: any) {
    if (err.response.status === 404) {
      this.modalMess = this.$t('common.message.topic_not_exist') as string
      this.modalAction = () => this.jumpToList()
    } else if (err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY') {
      this.modalMess = this.$t('common.message.group_over_size') as string
      this.modalAction = () => {}
    } else if (
      err.response.status === 422 &&
      err.response.data.message === 'MAX_TOTAL_FILE_SIZE'
    ) {
      this.modalMess = this.$t('common.message.upload_over_size', {
        max: err.response.data.upload_max_file_size
      }) as string
      this.modalAction = () => {}
    } else {
      this.modalMess = this.$t('common.message.update_fail') as string
      this.modalAction = () => this.jumpToList()
    }
    this.$bvModal.show('modal-error-topic-create')
    this.disableCheckDate = false
  }

  /**
   * Validation
   *
   * Validation for group tags
   */
  validateSelectGroupTag() {
    if (this.createTopic.topic_detail.topic_type === ETopicType.COMMON_TOPIC) {
      return this.selectedGroups.length !== 0
    }
    return true
  }

  /**
   * Validation
   *
   * Check content
   */
  handleContentError() {
    let returnVal = false
    if (
      !this.checkPTagInner() &&
      !this.content_text.trim() &&
      this.checkEmptyEditor
    ) {
      this.emptyContent = true
    } else if (
      new Blob([this.content_text]).size > 80000 ||
      _.toArray(this.content_text).length > 20000
    ) {
      this.overLengthContent = true
    } else if (checkScript(this.content_text)) {
      this.includesScriptTagContent = true
    } else {
      this.emptyContent = false
      this.overLengthContent = false
      returnVal = true
    }
    return returnVal
  }

  /**
   * Validation
   *
   * Validate min date
   */
  dateFormat(format?: string) {
    return format
      ? this.moment()
          .add(-1, 'second')
          .format(format)
      : this.moment().add(-1, 'second')
  }

  /**
   * Get inner text only
   */
  removeHTML(text: string) {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = text
    return tmp.textContent || tmp.innerText || ''
  }

  /**
   * Check that DOM inner text have another tag
   */
  checkPTagInner() {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = this.createTopic.topic_detail.content
    const elmnt = tmp.getElementsByTagName('p')
    let innerTextofP = ''
    for (let index = 0; index < elmnt.length; index++) {
      const el = elmnt[index]
      innerTextofP = innerTextofP + el.innerHTML
      if (el.innerHTML.includes('<')) break
    }
    return innerTextofP.includes('<')
  }
}

class GroupNameVM extends IdName {
  selected: boolean = false

  constructor(init?: IdName, selected?: boolean) {
    super()
    Object.assign(this, init)
    this.selected = !!selected
  }
}
